import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

export default function PrivacyPolicy() {
  return (
    <Box id="privacyPolicy" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="left"
        color="text.secondary"
      >
        PRIVACY POLICY
        <br />
        At Crystal Pug Pty Ltd, we value your comments, suggestions and feedback. Our Privacy Policy sets out how we collect, use and disclose "personal information" and other "non personal information" we have gathered about you.
        <br /><br />
        What is Personal Information and why do we collect it from you?
        <br />
        ======================
        <br />
        'Personal Information' is information or an opinion that identifies you as an individual. We collect and store your email when you sign up for our newsletter, or where you nominate to specifically allow our games to collect your email. If you contact us directly for any reason, we may also keep a copy of that correspondence. Your email is collected only to keep you informed of new Crystal Pug products and changes to our existing products.
        <br /><br />
        Newsletter
        <br />
        ======================
        <br />
        When you elect to join our mailing list for the purpose of receiving our newsletter we may use that "personal information" to provide you with direct marketing communications about upcoming games, features of our websites and other events and promotions related to our products.
        <br />
        You may unsubscribe from our newsletter at any time. All you need to do is unsubscribe from the link in the letter, or let us know in writing that you want to be removed.
        <br /><br />
        Sensitive Information
        <br />
        ======================
        <br />
        Sensitive information is defined in the US Privacy Act (1974) to include information or opinion about such things as an individual's racial or ethnic origin, political opinions, membership of a political association, religious or philosophical beliefs, membership of a professional body, criminal records or health information.
        <br />
        Crystal Pug will not attempt to obtain nor record any sensitive information.
        <br /><br />
        Disclosure of Personal Information
        <br />
        ======================
        <br />
        We will not sell, trade, rent or disclose any of the personal information you provide to us for any reason, without your prior consent. Otherwise, your Personal Information will only be disclosed where required or allowable by law.
        <br /><br />
        Security of Personal Information
        <br />
        ======================
        <br />
        Your Personal Information is stored offsite in a manner that reasonably protects it from misuse, loss, unauthorized access, modification or disclosure.
        <br />
        We don't guarantee website links or policy of authorized third parties and you access those sites at your own risk.
        <br /><br />
        Age Limits
        <br />
        ======================
        <br />
        We do not knowingly collect or solicit personal data about or direct or target interest based advertising to anyone under the age of 13 or knowingly allow such persons to use our Services.  If you are under 13, please do not send any data about yourself to us, including your name, address, telephone number, or email address.  No one under the age of 13 may provide any personal data.  If we learn that we have collected personal data about a child under age 13, we will delete that data as quickly as possible.  If you believe that we might have any data from or about a child under the age of 13, please contact us.
        <br /><br />
        Access to your Personal Information
        <br />
        ======================
        <br />
        Under the Privacy Act, NPP 6 gives you the right to access the Personal Information we hold about you and to update and/or correct it, subject to certain exceptions. If you wish to access your Personal Information, please make out your request to us in writing at the address listed below.
        <br />
        Crystal Pug will not charge any fee for your access request, but may charge an administrative fee. Please be aware that in order to protect the Personal Information in our database, we will require identification from you before releasing any information.
        <br /><br />
        Collection of other 'Non-Personal' Information
        <br />
        ======================
        <br />
        Though Crystal Pug itself does not use cookies in order to track your movement around the web, our websites and apps may contain APIs from third parties. Specifically, the Crystal Pug website (https://crystalpug.com/) uses Google Analytics to help analyse how users use the site. The tool uses cookies to collect internet log information and visitor behaviour information in an anonymous form. The information generated by the cookie about your use of the website, including your IP address, is transmitted to Google. This information is then used to evaluate use of our website and to compile statistical reports on website activity.
        <br />
        Our third party partners, and their privacy policies are as follows:
        <br />
        Google Analytics: https://www.google.com/policies/privacy/
        <br />
        Unity Ads and Analytics: https://unity3d.com/legal/privacy-policy
        <br /><br />
        Your Rights and Options
        <br />
        ======================
        <br /><br />
        Opt-out of marketing emails and other direct marketing
        <br />
        ----------------------
        <br />
        You may opt-out of receiving promotional communications, such as marketing emails from us by following the instructions in such communications.
        <br /><br />
        Opt-out of targeted advertising
        <br />
        ----------------------
        <br />
        You can opt-out of interest-based advertising on mobile applications by checking the privacy settings of your Android or iOS device and selecting "limit ad tracking" (Apple iOS) or "opt-out of interest based ads" (Android).
        <br />
        Access the personal data we hold about you
        <br />
        ----------------------
        <br />
        If you request, we will provide you a copy of your personal data in an electronic format.
        <br /><br />
        Your other rights
        <br />
        ----------------------
        <br />
        You also have the right to correct your data, have your data deleted, object how we use or share your data, and restrict how we use or share your data. You can always withdraw your consent, for example by turning off GPS location sharing in your mobile device settings.
        <br />
        We will respond to all requests within a reasonable timeframe.  If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact a local data protection authority within the U.S. or European Economic Area for unresolved complaints.
        <br /><br />
        Policy Updates
        <br />
        ======================
        <br />
        This Policy may change from time to time. The most current version of our Privacy Policy can be found at https://crystalpug.com/privacy-policy/. We will always keep this up to date so that you know what information we will collect from you, how we may use it, and when we may disclose it.
        <br /><br />
        Complaints
        <br />
        ======================
        <br />
        You can lodge a complaint with us about any breach of our Privacy Policy at the address below.
        <br /><br />
        Privacy Policy Contact Information
        <br />
        ======================
        <br />
        If you have any questions about our Privacy Policy, please contact us at:
        <br />
        contact@crystalpug.com
        <br />
        <br />
        Privacy policy last updated Jul 2024
      </Typography>
    </Box>
  );
};