import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="Matthew Ota" src="/static/images/avatar/1.jpg" />,
    name: 'Matthew Ota',
    occupation: 'Owner, Designer & Engineer',
    testimonial:
      "",
  },
  {
    avatar: <Avatar alt="Chris Concepcion" src="/static/images/avatar/2.jpg" />,
    name: 'Chris Concepcion',
    occupation: 'Artist',
    testimonial:
      "",
  },
  {
    avatar: <Avatar alt="Zander Hulme" src="/static/images/avatar/3.jpg" />,
    name: 'Zander Hulme',
    occupation: 'Audio Designer',
    testimonial:
      "",
  },
  {
    avatar: <Avatar alt="James Fearn-Wannan" src="/static/images/avatar/4.jpg" />,
    name: 'James Fearn-Wannan',
    occupation: 'Animator',
    testimonial:
      "",
  },
  {
    avatar: <Avatar alt="Brenton Wright" src="/static/images/avatar/5.jpg" />,
    name: 'Brenton Wright',
    occupation: 'Artist',
    testimonial:
      "",
  },
  {
    avatar: <Avatar alt="Michael Thompson" src="/static/images/avatar/6.jpg" />,
    name: 'Michael Thompson',
    occupation: 'Artist',
    testimonial:
      "",
  },
];

const whiteLogos = [
  '/assets/images/crystalpug/CP50T.png',
  '/assets/images/crystalpug/CP50T.png',
  '/assets/images/crystalpug/CP50T.png',
  '/assets/images/crystalpug/CP50T.png',
  '/assets/images/crystalpug/CP50T.png',
  '/assets/images/crystalpug/CP50T.png',
];

const darkLogos = [
  '/assets/images/crystalpug/CP50T.png',
  '/assets/images/crystalpug/CP50T.png',
  '/assets/images/crystalpug/CP50T.png',
  '/assets/images/crystalpug/CP50T.png',
  '/assets/images/crystalpug/CP50T.png',
  '/assets/images/crystalpug/CP50T.png',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="about-us"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          About Us
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Crystal Pug is an independent games studio based in Brisbane, Australia.
          It was formed in 2016 by highly experienced professionals from the games industry.
          The team has decades of experience in digital entertainment including AAA games,
          VR simulations and computer-animated feature films at award winning companies
          such as Nintendo, Square Enix, THQ, SEGA, Konami and many more.
          Crystal Pug aims to bring the world games filled with joy!
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
